import { useNavigate } from "react-router-dom";
import { CalendlyWidget } from "../../components/calendlyWidget/calendlyWidget";
import { ServiceCarousel } from "../../components/service/serviceCarousel/serviceCarousel";
import { Wrapper } from "../../components/wrapper/wrapper";
import { useEvents } from "../../hooks/useEvents/useEvents";
import { useOverlayDialog } from "../../hooks/useOverlayDialog";
import { Helmet } from "react-helmet";
import './strategyCall.css';

export function StrategyCall() {
  const { Dialog, openDialog } = useOverlayDialog();
  const { isCapturedLead } = useEvents();
  const navigate = useNavigate();

  if (!isCapturedLead) {
    navigate('/');
  }

  return (
    <Wrapper className="strategyCallBG">
      <Helmet>
        <title>Book a strategy call | Krexendo property management</title>
        <meta name='description' content="We've Sent You More Information! But here's a breakdown.
        We use a 5-step property management system to transform your rental hassles into streamlined operations
        Check out how we work, and book a call now - We guarantee you'll be satisfied!
        Take the next step: schedule your free strategy call today" />
      </Helmet>
      <header>
        <div className="tag">We've Sent You More Information! But here's a breakdown</div>
        <h1>
          We use a 5-step property management system to transform your rental hassles into streamlined operations
        </h1>
        <h5>
          Check out how we work, and <span onClick={openDialog} className='accentBlue boldUnderline' style={{cursor: 'pointer'}}>
          book a call now</span> - We guarantee you'll be satisfied!
        </h5>
      </header>
      <ServiceCarousel />
      <button onClick={openDialog} className="mainCTA">Take the next step: schedule your free strategy call today</button>
      <Dialog>
        <CalendlyWidget />
      </Dialog>
    </Wrapper>
  )
}