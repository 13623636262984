import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useEvents } from '../../hooks/useEvents/useEvents';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import { useViewportWidth } from '../../hooks/useViewportWidth';

export function CalendlyWidget() {
  const { userInfo: {firstName, lastName, email}, postSignup } = useEvents();
  const device = useViewportWidth();

  useCalendlyEventListener({
    onEventScheduled: (e) => postSignup({ calendlyEventUrl: e.data.payload.event.uri}),
  });

  return (
    <InlineWidget
      url='https://calendly.com/krexendo/strategy-call'
      prefill={{ 
        name: `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`,
        email,
      }}

      pageSettings={{
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '00a2ff',
        textColor: '4d5055'
      }}
      utm={{ utmSource: 'Website' }}
      styles={device === 'desktop' ? {
        minWidth: '1000px',
        height: '630px'
      } : undefined}
    />
  );
};