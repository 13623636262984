import axios from "axios";
import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import { postToGoogleSheet } from "../../utils/postToGoogleSheet";
import { postFbEvent } from "../../utils/postFbEvent";
import { postGoogleConversion } from "../../utils/postGoogleConversion";
import { retrieveFbClickId } from "../../utils/retrieveFbClickId";
import { retrieveFbBrowserId } from "../../utils/retrieveFbBrowserId";
import { reducer, getInitialState } from "./reducer";
import { TLeadInfo, TPostLeadPayload } from "./types";
import { TGetScheduledEventPayload, getScheduledEventPhoneNumber } from "../../utils/getScheduledEventInvitee";

export function useEvents() {
  const [userInfo, dispatch] = useReducer(reducer, getInitialState());

  const postSignup = useCallback( async ( eventPayload : TGetScheduledEventPayload) => {
    const phoneNumber = await getScheduledEventPhoneNumber(eventPayload);
    if (!phoneNumber) return;

    const signupInfo = { phoneNumber };
    dispatch({type: 'SET_FETCHED_SIGNUP_USER_INFO', payload: signupInfo});

    try {
      await Promise.all([
        postToGoogleSheet({sheet: 'websiteSignup', userInfo: {...userInfo, ...signupInfo}}),
        postFbEvent({eventType: 'Schedule', userInfo: {...userInfo, ...signupInfo}}),
        postGoogleConversion({ eventType: 'signup' }),
      ]);
    } catch (e) {
      console.log(e);
    }
  }, [userInfo]);

  // lead fn | post fb and google leads
  const postLead = useCallback(
    async ({
      leadSource,
      firstName,
      lastName,
      email,
      unitCount,
    }: TPostLeadPayload
  ) => {
    if (email !== userInfo.email) {
      const leadInfo: TLeadInfo = { firstName, lastName, email, unitCount };
      dispatch({type: 'SET_SUBMITTED_LEAD_USER_INFO', payload: leadInfo});
      
      try {
        const fromLeadMagnet = leadSource === 'calculator';

        await Promise.all([
          postToGoogleSheet({sheet: fromLeadMagnet ? 'magnetLead' : 'directLead', userInfo: {...userInfo, ...leadInfo}}),
          postFbEvent({eventType: 'Lead', userInfo: {...userInfo, ...leadInfo}}),
          postGoogleConversion({ eventType: 'lead' }),
        ]);
      } catch (e) {
        console.log(e);
      }
    }
  }, [userInfo]);


  // view fn | posts fb views
  const postViewContent = useCallback(() => 
    postFbEvent({eventType: 'ViewContent', userInfo})
    .catch((e) => console.log(e)),
    [userInfo]
  );

  // gets user info
  useEffect(() => {
    async function setUserInfo() {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const { ip, country, region_code, city } = response.data;
  
        dispatch({
          type: 'SET_FETCHED_BASE_USER_INFO',
          payload: {
            ip,
            country,
            state: region_code,
            city,
            userAgent: navigator.userAgent,
            fbClickId: retrieveFbClickId(),
            fbBrowserId: retrieveFbBrowserId()
          }
        })
      } catch (e) {
        console.error(e)
      }
    }

    setUserInfo();
  }, []);

  const isCapturedLead = useMemo(() => userInfo.email !== '', [userInfo.email]);

  const hasFetchedBaseInfo = useMemo(() => userInfo.ip !== '', [userInfo.ip]);

  return { postLead, postViewContent, postSignup, isCapturedLead, hasFetchedBaseInfo, userInfo }
}


