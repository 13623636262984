import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import './inputField.css';
import { camelCaseToSpaced } from '../../utils/camelCaseToSpace';

type InputFieldProps = {
  fieldName: string,
  type?:  React.InputHTMLAttributes<HTMLInputElement>['type'],
  placeholder?: string,
  required?: boolean,
  defaultValue?: string | number,
  step?: number,
}

export function InputField({fieldName, type, placeholder, required, defaultValue, step}: InputFieldProps) {
  return (
    <div className={`inputField${type === 'checkbox' ? ' inLine' : ''}`}>
      <label htmlFor={fieldName}>{capitalizeFirstLetter(camelCaseToSpaced(fieldName))}</label>
      <input type={type} name={fieldName} placeholder={placeholder} required={required} value={defaultValue} step={step} />
    </div>
  )
}