import { ReactNode } from 'react';
import './overlayDialog.css'; // Optionally, include CSS for styling
import crossIcon from '../../imgs/close.png';

export type TOverlayDialogProps = {
  onClose: () => void,
  children: ReactNode,
};

export function OverlayDialog({ onClose, children }: TOverlayDialogProps) {
  return (
    <div className="overlay">
      <div className='overlayContent'>
        <button className='closeButton' onClick={onClose}>
          <img className='crossIcon' src={crossIcon} alt='cross' />
        </button>
        {children}
      </div>
    </div>
  );
};

