import React from "react";
import './wrapper.css';

type TWrapperProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  containerProps?: Omit<React.HtmlHTMLAttributes<HTMLDivElement>, "children">,
};

export function Wrapper(props: TWrapperProps) {
  const {className: passedWrapperClasses, containerProps, children, ...rest} = props;

  const wrapperClassName =  `wrapper ${passedWrapperClasses ?? ""}`;
  const containerClassName = `container ${containerProps?.className ?? ""}`;

  return (
    <main { ...rest } className={ wrapperClassName } >
      <div {...containerProps} className={ containerClassName }>
        { children }
      </div>
    </main>
  )
}