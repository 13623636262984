import axios from "axios";
import { sha256 } from 'js-sha256';
import { TUserInfo } from "../hooks/useEvents/types";

type TPostFbEventPayload = {
  eventType: 'Lead' | 'ViewContent' | 'Schedule',
  userInfo: TUserInfo,
}

export async function postFbEvent({ 
  eventType, 
  userInfo: {
    city,
    state,
    country,
    email,
    fbBrowserId,
    fbClickId,
    firstName,
    lastName,
    ip,
    userAgent,
    phoneNumber,
  }
}: TPostFbEventPayload) {
  const TOKEN = process.env.REACT_APP_DATASET_TOKEN;
  const DATASET_ID = process.env.REACT_APP_DATASET_ID;
  const API_VERSION = 'v12.0';
  const url = `https://graph.facebook.com/${API_VERSION}/${DATASET_ID}/events?access_token=${TOKEN}`;
  const unixTime = Math.floor(Date.now() / 1000);
  const eventId = ip.concat(String(unixTime));
  const fbcParam = fbClickId ? `fb.1.${unixTime*1000}.${fbClickId}` : null;
  const fbpParam = fbBrowserId ? fbBrowserId : null;

  const data = [
    {
      event_name: eventType,
      event_time: unixTime,
      action_source: 'website',
      event_source_url: 'https://www.krexendo.com/',
      event_id: eventId,
      user_data: {
        client_ip_address: ip,
        client_user_agent: userAgent,
        em: [cleanAndHash(email)],
        fn: [cleanAndHash(firstName, true)],
        ln: [cleanAndHash(lastName, true)],
        country: [cleanAndHash(country)],
        st: [cleanAndHash(state)],
        ct: [cleanAndHash(city)],
        ph: [cleanAndHash(phoneNumber)],
        fbc: fbcParam,
        fbp: fbpParam,
      },
    }
  ]
  
  try {
    const response = await axios.post(url, {data});
    if (!response) throw new Error('Network response was not ok');
  } catch (error) {
    console.error('Error:', error);
  }
}

function cleanAndHash(str: string, name?: boolean) {
  if (!str) return null;
  const raw = name ? str.toLowerCase() : str.toLowerCase().replaceAll(" ", "");
  return sha256(raw);
}