import { getSubdomainIndex } from "./getSubdomainIndex";
import { storeFbIdCookie } from "./storeFbIdCookie";

function formatFbClickId(fbclid: string) {
  const version = 'fb';
  const subdomainIndex = getSubdomainIndex();
  const creationTime = Date.now();
  return `${version}.${subdomainIndex}.${creationTime}.${fbclid}`;
}

export function retrieveFbClickId() {
  const urlParams = new URLSearchParams(window.location.search);
  const fbclid = urlParams.get('fbclid');

  if (fbclid) {
    const formattedFbClickId = formatFbClickId(fbclid);
    storeFbIdCookie(formattedFbClickId, 'click');
    return formattedFbClickId;
  } else {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('_fbc='))
      ?.split('=')[1];
    return cookieValue || '';
  }
}


