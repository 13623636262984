import { TUserInfo, TAction } from "./types";

export function reducer(state: TUserInfo, action: TAction): TUserInfo {
  switch (action.type) {
    case 'SET_FETCHED_BASE_USER_INFO':
      return setLocalStateAndReturn({ ...state, ...action.payload });

    case 'SET_SUBMITTED_LEAD_USER_INFO':
      return setLocalStateAndReturn({ ...state, ...action.payload });

    default:
      return state;
  }
}

function setLocalStateAndReturn<T extends object>(obj: T) {
  for (const [k, v] of Object.entries(obj)) {
    localStorage.setItem(k, v);
  }

  return obj;
}

export function getInitialState() {
  const initialState: TUserInfo = {
    ip: '',
    country: '',
    state: '',
    city: '',
    userAgent: '',
    fbClickId: '',
    fbBrowserId: '',
    firstName: '',
    lastName: '',
    email: '',
    unitCount: '',
    phoneNumber: '',
  };

  for (const [k, v] of Object.entries(initialState)) {
    const finalValue = localStorage.getItem(k) ?? v;
    initialState[k as keyof TUserInfo] = finalValue;
  }

  return initialState;
}