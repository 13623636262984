import { startTransition, useCallback, useEffect, useState } from "react";
import { ServiceBadge, TService } from "../serviceBadge/serviceBadge";
import './serviceCarousel.css';
import { SERVICES_COPY } from "../SERVICE_CONSTS";
import { useViewportWidth } from "../../../hooks/useViewportWidth";
import chevron from '../../../imgs/chevron.png';

export function ServiceCarousel() {
  const [activeService, setActiveService] = useState<TService>('tenantTrust');
  const KEYS = Object.keys(SERVICES_COPY) as TService[];
  const isDesktop = useViewportWidth() === 'desktop';

  const setNextService = useCallback(() => {
    startTransition(() => {
      setActiveService(curr => {
        const currIdx = KEYS.lastIndexOf(curr);
        return currIdx === KEYS.length - 1 ? KEYS[0] : KEYS[currIdx + 1];
      })
    });
  }, [KEYS]);

  const setPreviousService = useCallback(() => {
    startTransition(() => {
      setActiveService(curr => {
        const currIdx = KEYS.lastIndexOf(curr);
        return currIdx === 0 ? KEYS[KEYS.length - 1] : KEYS[currIdx - 1];
      })
    });
  }, [KEYS]);

  const chevronButton = (reverse?: boolean) => (
    <button onClick={reverse ? setPreviousService : setNextService} className="chevronButton">
      <img className={`chevron ${reverse ? 'reverse' : ''}`} alt="chevron" src={chevron}/>
    </button>
  );

  useEffect(() => {
    const carouselInterval = setInterval(setNextService, 7500);
    return () => clearInterval(carouselInterval);
  }, [setNextService]);

  return (
    <div className="carouselWrapper">
      {isDesktop && 
        <div className={'serviceIndicatorsContainer'}>
          {KEYS.map(k => (
              <ServiceBadge
                key={`${k}ServiceBadge`}
                active={activeService === k}
                type={k}
                onClick={() => setActiveService(k)}
              />
            ))}
        </div>}
        {!isDesktop &&
          <>
            <div className="divider" />
            <div className="mobileHeaderAndControlsContainer">
              {chevronButton(true)}
              <div className={'mobileServiceHeader'}>
                <span className="header">{SERVICES_COPY[activeService].header}</span>
                <span className="subheader">{SERVICES_COPY[activeService].subheader}</span>
              </div>
              {chevronButton()}
            </div>
          </>}
        <div className={`carouselContentContainer ${!isDesktop ? 'mobile' : ''}`}>
          <ul>
            {SERVICES_COPY[activeService].bullets.map((bullet, idx) => (
              <li key={`${activeService}Bullet${idx + 1}`}>{bullet}</li>
            ))}
          </ul>
        </div>
    </div>
  )
}

