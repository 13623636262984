import { generateRandomNumber } from "./generateRandomNumber";
import { getSubdomainIndex } from "./getSubdomainIndex";
import { storeFbIdCookie } from "./storeFbIdCookie";

function formatFbBrowserId() {
  const version = 'fb';
  const subdomainIndex = getSubdomainIndex();
  const creationTime = Date.now();
  const randomNumber = generateRandomNumber();
  return `${version}.${subdomainIndex}.${creationTime}.${randomNumber}`;
}
export function retrieveFbBrowserId() {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith('_fbp='))
    ?.split('=')[1];

  if (cookieValue) {
    return cookieValue;
  } else {
    const formattedFbBrowserId = formatFbBrowserId();
    storeFbIdCookie(formattedFbBrowserId, 'browser');
    return formattedFbBrowserId;
  }
}
