import axios from "axios";
import { TUserInfo } from "../hooks/useEvents/types";
import { capitalizeFirstLetter } from "./capitalizeFirstLetter";

type TPostToGoogleSheetPayload = {
  sheet: 'directLead' | 'magnetLead' | 'websiteSignup',
  userInfo: TUserInfo,
}

export async function postToGoogleSheet({
  sheet,
  userInfo: {
    firstName,
    lastName,
    email,
    unitCount,
    phoneNumber,
  }
}: TPostToGoogleSheetPayload) {
  const url = 'https://script.google.com/macros/s/AKfycbxpShKv3ZcTkt3f_zsfzb646bMdrwJZ_4OceHQ_0wkgrv19iTqLcUj9ewkvpzuKA9QJ/exec';

  try {
    const params = new URLSearchParams();
    params.append('sheet', sheet);
    params.append('firstName', capitalizeFirstLetter(firstName));
    params.append('lastName', capitalizeFirstLetter(lastName));
    params.append('email', email.toLowerCase());

    if (sheet === 'directLead' && unitCount) {
      params.append('unitCount', unitCount);
    }

    if (sheet === 'websiteSignup' && phoneNumber) {
      params.append('phoneNumber', phoneNumber);
    }

    const {data} = await axios.post(url, params);
    if (!data) throw new Error('Network response was not ok');
  } catch (error) {
    console.error('Error:', error);
  }
}
