
export const SERVICES_COPY = {
  tenantTrust: {
    header: "TenantTrust",
    subheader: "Screening",
    bullets: [
      'Background Checks: In-depth credit, employment, and rental history screenings.',
      'Legal Compliance: Adherence to all fair housing laws and regulations.',
      'Interview Process: Conducting tenant interviews to assess suitability.',
      'Approval Process: Clear criteria for tenant approval.',
      'Retention Programs: Initiatives to ensure high tenant satisfaction and long-term stays.',
    ],
  },
  upkeepMaster: {
    header: "UpkeepMaster",
    subheader: "Maintenance",
    bullets: [
      'Regular Inspections: Scheduled checks to identify maintenance needs.',
      'Preventive Maintenance: Proactive care to avoid future issues.',
      'Repair Coordination: Arrangement and supervision of repairs.',
      'Licensed Contractors: Engagement with certified professionals.',
      'Maintenance Reports: Detailed documentation of all maintenance activities.'
    ],
  },
  portfolioPro: {
    header: "PortfolioPro",
    subheader: "Management",
    bullets: [
      'Comprehensive Oversight: Full management of all day-to-day property operations.',
      'Performance Reporting: Monthly updates on property performance and financials.',
      'Rent Collection: Efficient collection and processing of rental payments.',
      'Lease Management: Handling lease renewals and terminations.',
      'Vendor Coordination: Management of relationships and contracts with service providers.',
    ],
  },
  roundTheClock: {
    header: "Round-the-Clock",
    subheader: "Care",
    bullets: [
      '24/7 Availability: Continuous support for tenants and property owners.',
      'Emergency Response: Immediate action for urgent issues and repairs.',
      'Communication Channels: Access via phone, email, and online chat.',
      'Issue Tracking: Logging and monitoring of reported issues until resolved.',
      'Tenant Assistance: Support with tenancy-related questions and concerns.',
    ],
  },
  clearCost: {
    header: "ClearCost",
    subheader: "Subscription",
    bullets: [
      'Transparent Pricing: Clear, straightforward subscription fees.',
      'No Hidden Fees: Guarantee of no unexpected costs.',
      'Flexible Plans: Monthly, quarterly, or annual payment options.',
      'Inclusive Services: All management services covered under the subscription.',
      'Predictable Budgeting: Consistent and predictable expenses for property owners.',
    ],
  }
};
