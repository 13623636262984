import axios from "axios";

export type TGetScheduledEventPayload = {
  calendlyEventUrl: string,
};

// returns invitee phone number for outbound call meeting types
export async function getScheduledEventPhoneNumber({ calendlyEventUrl }: TGetScheduledEventPayload) {
  const TOKEN = 'eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzIwMTY4ODY2LCJqdGkiOiI2OWM4NTUyMi1mOGMwLTRiOGMtYjY5MC02NWEwMTNlZjI2NWIiLCJ1c2VyX3V1aWQiOiI1OTg3NzBlZS1hYjcyLTQ2MWMtOWNhNi0yOTZjODMzMWE3YjYifQ.Fy4Eei12NuosQq-52bSBPC9XYSpQusHzGb3jEAulbu7OOtAP1QLVkHrKUxI2BroYgoNS0-taZKPaqNNl5okKQw'
  
  try {
    const { data } = await axios.get(calendlyEventUrl, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": 'application/json',
      }
    });

    const phoneNumber = data.resource.location.location as string | null;
  
    return phoneNumber ? stripPhoneNumber(phoneNumber) : null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

function stripPhoneNumber(phoneNumber: string) {
  // Remove all non-numeric characters from the phone number
  const strippedNumber = phoneNumber.replace(/\D/g, '');
  
  // Return the stripped number
  return strippedNumber;
}



