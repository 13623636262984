declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

const GOOGLE_CONVERSIONS = {
  lead: {
    send_to: "AW-16630729300/ZGu6CNTq8b4ZENSUk_o9",
    value: 151,
  },
  signup: {
    send_to: "AW-16630729300/blYkCP_Ql8AZENSUk_o9",
    value: 1512,
  }
}

type TPostGoogleConversionPayload = {
  eventType: keyof typeof GOOGLE_CONVERSIONS,
  redirectUrl?: string,
}

export function postGoogleConversion({ eventType, redirectUrl }: TPostGoogleConversionPayload) {
  const event_callback = function () {
    if (typeof redirectUrl !== 'undefined') {
      window.location.href = redirectUrl;
    }
  };

  const { send_to, value } = GOOGLE_CONVERSIONS[eventType];

  window.gtag('event', 'conversion', {
    send_to,
    value,
    currency: 'CAD',
    event_callback,
  });

  return false;
}


