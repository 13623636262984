import { Route, BrowserRouter, Routes } from 'react-router-dom';
import logo from './imgs/logo.svg';
import { Home } from './pages/home/home';
import { Calculator } from './pages/calculator/calculator';
import { useEvents } from './hooks/useEvents/useEvents';
import { StrategyCall } from './pages/strategyCall/strategyCall';
import { useEffect, useRef } from 'react';
import './App.css';

function App() {
  const { postViewContent, hasFetchedBaseInfo } = useEvents();
  const hasPostedViewContent = useRef(false);

  useEffect(() => {
    if (!hasPostedViewContent.current && hasFetchedBaseInfo) {
      postViewContent();
      hasPostedViewContent.current = true;
    }
  }, [hasFetchedBaseInfo, postViewContent]);
  
  return (
    <BrowserRouter>
      <div className="App">
        <nav className='navbar'>
          <div className='logoContainer'>
            <img className='logo' src={logo} alt="Logo" />
          </div>
        </nav>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/calculator" Component={Calculator} />
          <Route path="/strategy-call" Component={StrategyCall} />
        </Routes>
        <footer>
          <img src={logo} alt="logo" />
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
