import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { useEvents } from '../../hooks/useEvents/useEvents';
import { InputField } from "../../components/inputField/inputField";
import { InputForm } from "../../components/form/form";
import { getAsType } from "../../utils/getAsType";
import { Wrapper } from "../../components/wrapper/wrapper";
import { useOverlayDialog } from "../../hooks/useOverlayDialog";
import { Helmet } from "react-helmet";
import redBlob from '../../imgs/red-blob.svg'
import frustratedPerson from '../../imgs/Student stress-rafiki.svg';
import blueBlob from '../../imgs/blue-blob.svg'
import proudPerson from '../../imgs/Self confidence-rafiki.svg';
import './home.css';
import { useViewportWidth } from "../../hooks/useViewportWidth";

export function Home() {
  const navigate = useNavigate();
  const { postLead } = useEvents();
  const { Dialog, openDialog } = useOverlayDialog();
  const isOnMobile = useViewportWidth() === "mobile";

  const onSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget as HTMLFormElement);
    const getter = (key: string) => formData.get(key)?.toString();
    const getAsString = (key: string) => getAsType<string>(getter, key);

    const firstName = capitalizeFirstLetter(getAsString('firstName'));
    const lastName = capitalizeFirstLetter(getAsString('lastName'));
    const email = getAsString('email').toLowerCase();
    const unitCount = getAsString('unitCount');

    postLead({
      leadSource: "home",
      firstName,
      lastName,
      email,
      unitCount,
    }).then(() => navigate('/strategy-call'));
  }, [navigate, postLead]);

  const CTAButton = <button className="mainCTA" onClick={openDialog}>Start Your Journey to Property Success!</button>;

  return (
    <>
      <Helmet>
        <title>Get started | Krexendo property management</title>
        <meta name='description' content="For Sudbury Rental Property Owners Only.
        Escape the Landlord Trap and Instantly Transform Property Stresses into Year-Round Success with transparent,
        Cancel-Anytime Management! Trust us with your rentals and Relinquish property management troubles.
        Secure your spot — only 5 available for new clients this summer! Start Your Journey to Property Success!
        Get Started Today with Krexendo Property Management Services! Send me the details!
        Feeling Stuck in the Rental Property Grind?**

        Owning rental properties in Sudbury should be a path to financial freedom, but right now, it's more like a constant headache. 
 
        Ever felt like this?
        Tenant Troubles: 'I can't deal with another call at 2 AM because the toilet's broken again.'
        Vacancy Stress: 'I thought this rental would be a steady income stream, but it’s been empty for three months. I’m bleeding money!'
        'Why does something always break the moment I think everything is going smoothly?'
        'I’m sick of awkward conversations and endless excuses about late rent.'
        'Legal Worries: 'Am I even following the latest landlord-tenant laws? I can’t afford a legal slip-up.'

        It’s not just about the money—it's the stress, the sleepless nights, and the feeling of being trapped in a never-ending cycle of problems. You’re watching your dream of passive income and financial independence slip away, replaced by constant anxiety and frustration. 
        You’ve tried managing it all yourself, hiring a cheap property manager, or even considering selling the property, but nothing seems to get you where you want to be. It feels like you’re pouring time, energy, and money into a bottomless pit.

        Imagine this instead:
        Never get a late-night emergency call again. We handle everything, from tenant selection to routine maintenance.
        No more stress about empty properties. Our proven tenant acquisition strategies keep your units filled.
        Proactive and reliable maintenance means fewer unexpected problems and happier tenants.
        Automated systems and strict policies ensure rent is collected on time, every time.
        Stay compliant with all local regulations without lifting a finger. Our experts handle it for you.

        Our approach ensures your rental properties become a source of steady, stress-free income. By leveraging our expertise, advanced systems, and proactive management, you’ll experience the true potential of your investment.
        Don’t let your rental property become a burden. Let us turn it into the lucrative, hands-off investment you always wanted it to be.
        " />
      </Helmet>
      <Wrapper className="homeBG" containerProps={{className: 'padTop'}}>
        <header>
          <div className="tag">{'Sudbury rental investors'} {isOnMobile ? <br/> : '-'} {'your game changer is here'}</div>
          <h1>CONVERT RENTAL STRESS TO STEADY SUCCESS <span className='accentBlue italic'>Today</span> WITH TRANSPARENT, RISK-FREE MANAGEMENT!</h1>
          <h5>Limited opportunity — accepting just 5 new property owners this summer!</h5>
        </header>
        {CTAButton}
        <Dialog>
          <InputForm onSubmit={onSubmit}>
            <h3>Take the First Step Towards Stress-Free Ownership</h3>
            <InputField fieldName="firstName" placeholder="john" required={true} />
            <InputField fieldName="lastName" placeholder="doe" required={true} />
            <InputField type="email" fieldName="email" placeholder="example@email.com" required={true} />
            <InputField type="number" fieldName="unitCount" placeholder="12" required={true} />
            <button type="submit">Send me the details!</button>
          </InputForm>
        </Dialog>
      </Wrapper>
      <div className="fullWidthBanner">
        <span className="bold"><span className="accentOrange">$5M+</span> In Assets Under Management</span>
        Join the many satisfied property owners who trust us with their properties.
      </div>
      <div className="beigeBG">
        <img className="sectionImgBlob redBlob" src={redBlob} alt="background blob"/>
        <Wrapper className="homeSectionWrapper" containerProps={{className: "homeSectionContainer"}}>
          <div className="textSectionContainer">
            <h2>Feeling Stuck in the Rental Property Grind?</h2>
            <p>
              Owning rental properties in Sudbury should be a path to financial freedom, but right now, it's more like a constant headache.
              <br/>
              <br/>
              <span className="underline">Ever felt like this:</span>
              <br/>
              <br/>
              <span className="italic">"I can't deal with another call at <span className="accentOrange">2 AM</span> because the toilet's broken again."</span>
              <br/>
              <br/> 
              <span className="italic">"I thought this rental would be a steady income stream, but <span className="accentOrange">it’s been empty</span> for three months. I’m bleeding money!"</span>
              <br/>
              <br/>
              <span className="italic">"Why does <span className="accentOrange">something always break</span> the moment I think everything is going smoothly?"</span>
              <br/>
              <br/>
              <span className="italic">"I’m <span className="accentOrange">sick of awkward conversations</span> and endless excuses about late rent."</span>
              <br/>
              <br/>
              <span className="italic">"Am I even following the latest <span className="accentOrange">landlord-tenant laws?</span> I can’t afford a legal slip-up."</span>
            </p>
          </div>
          <img className="sectionImg" src={frustratedPerson} alt="frustrated person"/>
        </Wrapper>
        <div className="partialBanner">
          <p>
            It’s not just about the money—it's the stress, the sleepless nights, and the feeling of being trapped in a never-ending cycle of problems.
            You’re watching your dream of passive income and financial independence slip away, replaced by constant anxiety and frustration.
          </p>
        </div>
        <img className="sectionImgBlob blueBlob" src={blueBlob} alt="background blob"/>
        <Wrapper className="homeSectionWrapper" containerProps={{className: "homeSectionContainer reverse"}}>
          <div className="textSectionContainer">
            <h2>Imagine this instead!</h2>
            <p>
              <span className="accentBlue">Never get a late-night emergency call again.</span> We handle everything, from tenant selection to routine maintenance.
              <br/>
              <br/> 
              <span className="accentBlue">No more stress about empty properties.</span> Our proven tenant acquisition strategies keep your units filled.
              <br/>
              <br/>
              Proactive and <span className="accentBlue">reliable maintenance</span> means fewer unexpected problems and happier tenants.
              <br/>
              <br/>
              Automated systems and strict policies ensure <span className="accentBlue">rent is collected on time</span>, every time.
              <br/>
              <br/>
              Stay <span className="accentBlue">compliant with all local regulations</span> without lifting a finger. Our experts handle it for you.
            </p>
          </div>
          <img className="sectionImg supported" src={proudPerson} alt="Proud person"/>
        </Wrapper>
      </div>
      <div className="fullWidthBanner gapped">
        <div>
          <h1 className="accentOrange asWritten bold">Let's get you there</h1>
          <p>
            Our approach ensures your rental properties become a source of steady, stress-free income. By leveraging our expertise, advanced systems, and proactive management, you’ll experience the true potential of your investment.
            <br />
            <br />
            Don’t let your rental property become a burden. Let us turn it into the lucrative, hands-off investment you always wanted it to be.
          </p>
        </div>
        {CTAButton}
      </div>
    </>
  );
}