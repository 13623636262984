import activeBadge from '../../../imgs/Active=true.svg';
import inactiveBadge from '../../../imgs/Active=false.svg';
import './serviceBadge.css';
import { SERVICES_COPY } from "../SERVICE_CONSTS";

type TServiceBadgeProps = {
  active?: boolean,
  type: TService,
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
};

export function ServiceBadge({ active, type, onClick}: TServiceBadgeProps) {
  const {header, subheader} = SERVICES_COPY[type];
  return (
    <div onClick={onClick} className={'badgeWrapper'}>
      <div className='badgeCopyContainer'>
        <span className='badgeHeader'>{header}</span>
        <span className='badgeSubheader'>{subheader}</span>
      </div>
      <img className={'badge'} src={active ? activeBadge : inactiveBadge} alt={`${active ? 'active' : 'inactive'} badge`} />
    </div>
  )
}

export type TService = keyof typeof SERVICES_COPY;

