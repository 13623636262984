import { useState, useEffect } from 'react';

export function useViewportWidth() {
  const [viewportType, setViewportType] = useState<'mobile' | 'desktop'>(() => {
    // Initial check based on window.innerWidth
    return window.innerWidth <= 600 ? 'mobile' : 'desktop';
  });

  useEffect(() => {
    const handleResize = () => {
      const newViewportType = window.innerWidth <= 600 ? 'mobile' : 'desktop';
      setViewportType(newViewportType);
    };

    // Add event listener to window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return viewportType;
};

