import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { OverlayDialog } from '../components/overlayDialog/overlayDialog';

export function useOverlayDialog() {
  const [shouldShowDialog, setShouldShowDialog] = useState(false);

  const onOpen = useCallback(() => {
    setShouldShowDialog(true);
    document.body.style.overflow = 'hidden';
  }, []);

  const onClose = useCallback(() => {
    setShouldShowDialog(false);
    document.body.style.overflow = 'initial';
  }, []);

  const Dialog = useCallback(({ children }: { children: ReactNode }) => 
    shouldShowDialog ? <OverlayDialog onClose={onClose} children={children} /> : <></>
  , [onClose, shouldShowDialog]);

  useEffect(() => {
    return onClose
  }, [onClose]);

  const output = useMemo(() => ({ Dialog, openDialog: onOpen, closeDialog: onClose }), [Dialog, onOpen]);

  return output;
}
