import { InputField } from "../../components/inputField/inputField";
import { useCallback, useEffect, useState } from "react";
import { RealestateAnalysisInput, RealEstateDealAnalyser } from "./realEstateDealAnalyser";
import { marked } from "marked";
import html2pdf from 'html2pdf.js';
import logo from '../../imgs/logoInverted.svg';
import { InputForm } from '../../components/form/form';
import { getAsType } from "../../utils/getAsType";
import { useEvents } from "../../hooks/useEvents/useEvents";
import './calculator.css';
import { Wrapper } from "../../components/wrapper/wrapper";
import { useOverlayDialog } from "../../hooks/useOverlayDialog";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

export function Calculator() {
  return (
    <Wrapper className="calc">
      <Helmet>
        <title>1-Minute Deal Mastery Calculator | Krexendo property management</title>
        <meta name='description' content="The 1-Minute Deal Mastery Calculator We use to buy sudbury units Like clockwork!
        Unlock the power of Data for confident real estate purchases :). Download my analysis sheet" />
      </Helmet>
      <header>
        <h1>
          The 1-Minute Deal Mastery Calculator <span className='accentBlue'>We use to buy sudbury units</span> Like clockwork!
        </h1>
        <h5>
          Unlock the power of <span className='boldUnderline'>Data</span> for confident real estate purchases :)
        </h5>
      </header>
      <DealAnalysisForm />
    </Wrapper>
  )
}

function DealAnalysisForm() {
  const { postLead, isCapturedLead } = useEvents();
  const { Dialog, openDialog, closeDialog } = useOverlayDialog();
  const [ URLSearchParams ] = useSearchParams();
  const isPreCaptured = URLSearchParams.get('state') === 'captured';
  const [analysisResult, setAnalysisResult] = useState<RealEstateDealAnalyser>();
  const [shouldDownloadResult, setShouldDownloadResult] = useState(false);

  const onCalculatorSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);
    const result = getAnalysis(formData);
    setAnalysisResult(result);

    if (isPreCaptured || isCapturedLead) {
      setShouldDownloadResult(true);
    } else {
      openDialog();
    }
  }, [isCapturedLead, isPreCaptured, openDialog]);

  const onDialogSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);
    const getter = (key: string) => formData.get(key);
    const getAsString = (key: string) => getAsType<string>(getter, key);

    const firstName = getAsString('firstName');
    const lastName = getAsString('lastName');
    const email = getAsString('email');
  
    postLead({leadSource: 'calculator', email, firstName, lastName});
    setShouldDownloadResult(true);
    closeDialog();
  }, [closeDialog, postLead]);

  useEffect(() => {
    if ((isPreCaptured || isCapturedLead) && analysisResult && shouldDownloadResult) {
      downloadResults(analysisResult.getFullResultsMarkdown());
      setShouldDownloadResult(false);
    }
  }, [analysisResult, isCapturedLead, isPreCaptured, shouldDownloadResult]);

  return (
    <>
      <InputForm onSubmit={onCalculatorSubmit}>
        <InputField fieldName="salePrice" placeholder="400,000" required={true} type="number" />
        <InputField fieldName="downpaymentPercentage" placeholder="20" required={true} type="number" />
        <InputField fieldName="annualMortgageInterestRate" placeholder="5" required={true} type="number" step={0.01} />
        <InputField fieldName="mortgageAmortization" placeholder="25" required={true} type="number" />
        <InputField fieldName="monthlyHoaDues" placeholder="0" type="number" />
        <InputField fieldName="vacancyRate" placeholder="2.36" required={true} type="number" step={0.01}/>
        <InputField fieldName="totalMonthlyRent" placeholder="1800" required={true} type="number" />
        <InputField fieldName="unitCount" placeholder="1" required={true} type="number" />
        <InputField fieldName="newConstructionBuilding" type="checkbox" />
        <button type="submit">Download my analysis sheet</button>
      </InputForm>
      <Dialog>
        <InputForm onSubmit={onDialogSubmit}>
        <InputField fieldName="firstName" placeholder="john" required={true} />
        <InputField fieldName="lastName" placeholder="doe" required={true} />
        <InputField type="email" fieldName="email" placeholder="example@email.com" required={true} />
        <button type="submit">Download my analysis sheet</button>
        </InputForm>
      </Dialog>
    </>
  )
}

function getAnalysis(formData: FormData) {
  const getAsNumber = (key: string) => getAsType<number>(
    (key: string) => formData.get(key), 
    key,
  );

  const dealAnalyserInput: RealestateAnalysisInput = {
    salePrice: getAsNumber('salePrice'),
    downpaymentPercentage: getAsNumber('downpaymentPercentage'),
    annualMortgageInterestRate: getAsNumber('annualMortgageInterestRate'),
    monthlyHoaDues: getAsNumber('monthlyHoaDues'),
    monthlyRent: getAsNumber('totalMonthlyRent'),
    mortgageAmortization: getAsNumber('mortgageAmortization'),
    vacancyRate: getAsNumber('vacancyRate'),
    unitCount: getAsNumber('unitCount'),
    newConstruction: formData.get('newConstructionBuilding') !== null,
  }

  return new RealEstateDealAnalyser(dealAnalyserInput);
}

async function downloadResults(markdownText: string) {
  const htmlContent = await marked(markdownText);
  const tempContainer = document.createElement('div');
  tempContainer.innerHTML = htmlContent;

  // Add logo to the top right corner
  const logoImg = document.createElement('img');
  logoImg.src = logo;
  logoImg.style.position = 'absolute';
  logoImg.style.bottom = '10px';
  logoImg.style.right = '10px';
  logoImg.style.width = '100px';

  tempContainer.style.position = 'relative';
  tempContainer.appendChild(logoImg);
  
  const opt = {
    margin: 0.5,
    filename: `KrexendoDealAnalysisSheet.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  };

  html2pdf().from(tempContainer).set(opt).save().finally();
}